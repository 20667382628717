import React, { useState, useContext } from 'react'
import { NewsItem } from './NewItem';
import NewsUpload from './NewsUpload';
import { useAuth0 } from '@auth0/auth0-react';
import { context } from '../context';
import { sortByDate } from '../helpers';
import { FacebookEmbed } from 'react-social-media-embed';

export const News = () => {
    const { appState, setAppState } = useContext(context);
    const [addNewItem, setAddNewItem] = useState(false);
    const { isAuthenticated } = useAuth0();

    const goBackToNews = () => {
        setAddNewItem(false);
    }
    const renderNewsItems = () => {
        if (appState.news && appState.news.length > 0)
        {
            return appState.news.sort((a,b) => sortByDate(a, b)).map((news, index) => {
                return <NewsItem key={index} title={news.title} news={news.news} pic={news.pic} date={news.date} id={news._id}/>
            })
        } 
        else {
            return <h3>No News Items</h3>
        }

    }

    return (
        <div className="text-dark fade-in news-container" id="news">
                        <h1 className='mb-2 font-weight-bold text-center'>{addNewItem ? 'Add New News' : 'News'}</h1>

<div class="powr-facebook-feed" id="dd5b605a_1727031313"></div><script src="https://www.powr.io/powr.js?platform=html"></script></div>
        )
}
