import { KittyCard } from './KittyCard';

export const AdoptACage = () => {
  return (
    <div>
      <KittyCard title='Adopt-A-Cage' pic={require('../assets/cagey.png')}>
        <p className="mb-1">Nine Lives Adopt-A-Cage program gives donors an opportunity to support the shelter in a very unique way.</p>
        <p className="mb-1">Your monthly donation helps to provide shelter residents with food and medical attention.</p>
        <p className="mb-1">This is a great program for anybody who loves cats and can not have a cat of their own! </p>
        <p className="mb-1">When you Adopt-A-Cage you will receive a picture and information on the cat that is presently in that cage.</p>
        <p className="mb-1">You will also receive our regular newsletter. </p>
        <p className="mb-1">We welcome our sponsors to come in and visit their cats.</p>
        <p className="mb-1">The shelter is open on Saturdays from 11am – 2pm.</p>
        <p className="mb-1">Adopt-A-Cage makes a wonderful gift for someone who you really care about.</p>
        <p className="mb-1">When you sponsor a cat through Adopt-A-Cage as a gift we will notify the recipient of your kindness and send them a picture of the resident of their sponsored cage.</p>
        <p className="mb-1">We hope that you will consider becoming one of our Adopt-A-Cage Sponsors.</p>
        <p className="mb-1">Thank you for supporting Nine Lives of Norton, a non-profit, all-volunteer, no-kill shelter.  </p>
        <p className="mb-1">All donations are tax deductible.</p>
      </KittyCard>
    </div>
  );
}