import React, { useEffect, useState } from 'react';
import LoadingBar from './LoadingBar';

const PreloadAssets = (props) => {
  const { children, assets } = props;
  const [progress, setProgress] = useState(0);
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {
    const preloadAssets = async () => {
      try {
        const promises = assets.map(async (asset) => {
          const img = new Image();
          img.src = asset;

          return new Promise((resolve) => {
            img.onload = () => {
              setProgress((prevProgress) => prevProgress + 100 / assets.length);
              resolve();  
            };
          });
        });

        await Promise.all(promises);

        setAssetsLoaded(true);
      } catch (error) {
        console.error('Error preloading assets:', error);
      }
    };

    preloadAssets();
}, [assets]);
  return assetsLoaded ? children : <LoadingBar progress={progress/2} />;
};

export default PreloadAssets;
