import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Input, Button, Label } from 'reactstrap';
import { context } from '../context';
import { sortByDate } from '../helpers';

const noImage = require('../assets/no-image.png');

const NewsUpload = (props) => {
    const { appState, setAppState } = useContext(context);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedImage, setUploadedImage] = useState('');
    const [imageUrl, setImageUrl] = useState(noImage);
    const [info, setInfo] = useState({
        date: '',
        title: '',
        news: '',
        pic: ''
    });
  
    const handleFileChange = (event) => {
      event.preventDefault(); // Prevent the default behavior for the entire form
    
      const file = event.target.files[0];
    
      // Display a preview of the selected image
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    
      setSelectedFile(file);
    };
    

  const handleUpload = async () => {
    setAppState({ ...appState, isLoading: true });
  
    if (selectedFile) {
    // Example using FormData
    const formData = new FormData();
    formData.append('image', selectedFile);

    // Make a POST request to your server endpoint
    await axios.post('https://ninelivesapi.jeffmcgowan.net/upload', formData)
      .then(response => {
        const newInfo = { ...info, pic: response.data.location };
        setUploadedImage(response.data.location);
        setInfo(newInfo)
        console.log(newInfo);
        axios.post('https://ninelivesapi.jeffmcgowan.net/api/news', newInfo)
        .then(res => {
          setAppState({ ...appState, news: appState.news.concat({...newInfo, _id: res.data._id }).sort(sortByDate), isLoading: false });
        })
      })
      .catch(error => {
        console.log(error);
      });
    } else {
      console.log(info);
      const res = await axios.post('https://ninelivesapi.jeffmcgowan.net/api/news', info);
      setAppState({ ...appState, news: appState.news.concat({...info, _id: res.data._id }).sort(sortByDate), isLoading: false });
    }
    props.goBackToNews();
    
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prevData) => ({ ...prevData, [name]: value }));
    console.log(info);
  };

  const handleFileTouchEnd = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <div className='mb-5'>
        <Label for="title" className='mb-0'>Title</Label>
        <Input type="text" name="title" className='mb-2' onChange={handleChange} />
        <Label for="news" className='mb-0'>News</Label>
        <Input type="textarea" name="news" className='mb-2' onChange={handleChange} />
        <Label for="date" className='mb-0'>Date</Label>
        <Input type="date" name="date" className='mb-2' onChange={handleChange} />
        <Label for="image" className='mb-0'>Image</Label>
        <Input
          type="file"
          id="fileInput"
          name="pic"
          className='m-2'
          onChange={handleFileChange}
          onTouchEnd={handleFileTouchEnd}
        />
        <img src={imageUrl} style={{ maxHeight: '250px'}} className='m-2'></img>
        <div className='d-flex justify-content-center'>
          <Button onClick={props.goBackToNews}>Cancel</Button>
          <Button className='btn btn-primary' color='primary' onClick={handleUpload}>Submit news</Button>
        </div>
    </div>
  );
};

export default NewsUpload;