import React from 'react'
import { KittyCard } from './KittyCard';
export const Foster = () => {
    return (
        <KittyCard pic={require('../assets/kitties4.png')} title='Foster Program'>
            <h6 className="mb-3">Nine Lives of Norton occasionally needs caring, and loving foster families. </h6>
            <p className="mb-1">All of our kittens are kept in foster homes until they are ready for adoption.  Kittens need extra socializing and lots of loving.</p>
            <p className="mb-1">Sometimes a foster home is needed for a pregnant or nursing mom. </p>
            <p className="mb-1">For more information please send us an email: <a href='mailto:ninelivesofnorton@yahoo.com'>ninelivesofnorton@yahoo.com</a></p>
        </KittyCard>
        )
}
