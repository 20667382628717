import { Card, CardBody, CardText, CardImg } from 'reactstrap';

export const KittyCard = (props) => {

  return (
    <div className="content center text-dark mx-auto fade-in">
      <h1 className='mb-2 font-weight-bold'>{props.title}</h1>
        <Card className="Card">
            <div className='card-img-container pt-2'>
                <CardImg
                className='kitty-header'
                alt="Card image cap"
                src={props.pic}
                top
                />
            </div>
            <CardBody>
                <CardText tag={props.textSize ?? 'h6'} className='kitty-card-text'>
                    {props.children}
                </CardText>
            </CardBody>
        </Card>

    </div>
  );
}