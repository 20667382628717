import React from 'react'

export const Footer = () => {
    return (
    <footer class="footer">
      <div class="text-center bg-dark">
        <span class="text-muted">Nine Lives of Norton</span>
      </div>
    </footer>
    )
}
