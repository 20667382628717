import { createContext, useState } from 'react'

// Create new context:
export const context = createContext()

// Create new provider component:
export const Provider = (props) => {
    const [appState, setAppState] = useState({
      news: null,
      isLoading: true
    })
  
    // Prepare values to share:
    const val = {
        appState,
        setAppState
    }
  return (
    <context.Provider value={val}>
      {props.children}
    </context.Provider>
  )
}