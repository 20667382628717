import React from 'react'
import { KittyCard } from './KittyCard';

export const Volunteers = () => {
  return (
    <KittyCard title='Volunteer' pic={require('../assets/f4.png')}>
        <p className="mb-1">Nine Lives of Norton is an all volunteer staff.  We rely on our volunteers to nurture and care for our feline residents.</p>
        <p className="mb-1">If you love kittens and cats consider becoming a Nine Lives of Norton Volunteer. </p>
        <p className="mb-1">We need volunteers for a wide variety of tasks.</p>
        <p className="mb-1">At the shelter, the kitties daily needs are met by our

        volunteers twice a day - every day. 

        Twice Daily Shelter Duties include:  

        Feeding the kitties
        Cleaning cages and litter boxes
        Washing dishes
        Offering love and affection to our feline residents!</p>
  </KittyCard>
  )
}
