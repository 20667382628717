import { KittyCard } from './KittyCard';
import { useState, useEffect } from 'react'
import petFinder from '../helpers/petFinderApi'
export const Available = () => {
    const [pets, setPets] = useState([]);
  const url = "https://api.petfinder.com/v2/organizations";

  const petsRequest = async () => {
    const res = await petFinder.animal.search({organization: 'MA288'})
    console.log(res)
  }
  useEffect(() => {
    petsRequest()

  }, [pets]);
  return (
    <div>
      <KittyCard title='Available For Adoption' pic={require('../assets/cagey.png')}>
      </KittyCard>
    </div>
  );
}