import { useEffect } from 'react';
import { Col, Row } from 'reactstrap'
import { useState } from 'react'
import { News } from './News';
import NewsUpload from './NewsUpload';
export const Home = (props) => {
  const [delayedTime, setDelayedTime] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedTime(true)
    }, 1000);
    return () => clearTimeout(timer)
  }, [])
    return (
      <div className='mt-auto'>
        <div className="App-header text-dark">
          <Row>
            <div className='intro-container'>
              <div className='intro'>
                <img src={require("../assets/ninelivesclassiclogo.png")} className='logo' />  
              </div>
            </div>
          </Row>
          {/* <News news={props.news} /> */}
        </div>
      </div>
    );
  }