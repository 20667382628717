import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const NineLivesNavbar = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const { isAuthenticated, logout } = useAuth0();

  const logOut = () => {
    toggleNavbar();
    logout();
  }
  return (
    <div>
    <div className='text-center position-fixed w-100' style={{zIndex:100}} id="navbar">
      <Navbar color="dark" dark className='navbar'>
        <NavbarBrand href="/" className="me-auto nav-brand">
          Nine Lives of Norton
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <Link to="./" className="nav-item nav-link" onClick={toggleNavbar}>Home</Link>
            </NavItem>
            <NavItem>
              <Link to="/news" className="nav-item nav-link" onClick={toggleNavbar}>News</Link>
            </NavItem>
            <NavItem>
              <Link to="/adoptacage" className="nav-item nav-link" onClick={toggleNavbar}>Adopt a Cage Program</Link>
            </NavItem>
            {/* <NavItem>
              <Link to="/available" className="nav-item nav-link" onClick={toggleNavbar}>Available for Adoption</Link>
            </NavItem> */}
            <NavItem>
              <Link to="/help" className="nav-item nav-link" onClick={toggleNavbar}>How You Can Help</Link>
            </NavItem>
            <NavItem>
              <Link to="/surrenders" className="nav-item nav-link" onClick={toggleNavbar}>Surrenders</Link>
            </NavItem>
            <NavItem>
              <Link to="/foster" className="nav-item nav-link" onClick={toggleNavbar}>Foster Program</Link>
            </NavItem>
            <NavItem>
              <Link to="/volunteers" className="nav-item nav-link" onClick={toggleNavbar}>Volunteering</Link>
            </NavItem>
            <NavItem>
              <Link to="/contact" className="nav-item nav-link" onClick={toggleNavbar}>Contact Us</Link>
            </NavItem>
            <NavItem>
              <a href="https://www.facebook.com/NineLivesOfNorton/" target={"_blank"} className="nav-item nav-link" onClick={toggleNavbar}>Like us on Facebook</a>
            </NavItem>
            { isAuthenticated ?
            <NavItem>
              <Link to="/" className="nav-item nav-link" onClick={logOut}>Log out</Link>
            </NavItem>
            :
            <NavItem>
              <Link to="/login" className="nav-item nav-link" onClick={toggleNavbar}>Log in</Link>
            </NavItem>
            }
          </Nav>
        </Collapse>
      </Navbar>
    </div>
    </div>
  );
}