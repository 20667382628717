import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
const LoadingBar = ({ progress }) => {
  return (
    <div className='d-flex w-100 justify-content-center align-items-center' style={{height: '100vh'}}>
        <Spinner />
    </div>
  );
};

export default LoadingBar;
